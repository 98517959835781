
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'BaseSpinner',
  props: {
    show: Boolean,
    opaque: Boolean,
    full: Boolean,
    small: Boolean,
  },
  setup(props) {
    const svgViewBox = computed(() => {
      if (props.small) {
        return '21.904761904761905 21.904761904761905 43.80952380952381 43.80952380952381';
      }
      return '22.857142857142858 22.857142857142858 45.714285714285715 45.714285714285715';
    });

    const circleSizeAttrs = computed(() => {
      if (props.small) {
        return {
          cx: 43.80952380952381,
          cy: 43.80952380952381,
          strokeWidth: 3.8095238095238093,
        };
      }
      return {
        cx: 45.714285714285715,
        cy: 45.714285714285715,
        strokeWidth: 5.714285714285714,
      };
    });

    return {
      svgViewBox,
      circleSizeAttrs,
    };
  },
});

export const ACCREDITATION_EXPIRED = 'Accreditation Expired';
export const ACCREDITATION_STARTED = 'Accreditation Started';
export const ACCREDITATION_SUBMITTED = 'Accreditation Submitted';
export const ACCREDITED_AND_LINKED_BANK_ACCOUNT = 'at.accreditedAndLinkedBankAccount';
export const ACCOUNT_CREATED = 'Signed Up';
export const BANK_ACCOUNT_LINKED = 'Bank Account Linked';
export const USER_LOGIN = 'Signed In';
export const SIGNED_OUT = 'Signed Out';
export const SIGN_UP = 'Signup Started';
export const SIGN_UP_STEP = 'Signup Step Completed';
export const HUBSPOT_FORM_SUBMIT = 'hs.formSubmit';
export const IDENTITY_VERIFIED = 'Identity Verified';
export const ACCREDITATION_AFTER_REGISTER = 'Accreditation After Register';
export const ACCREDITED_ONCE = 'Accredited Account Created';
export const ACCREDITED_VERIFY = 'Accreditation Verified';
export const PAGE_RESOLVED = 'at.pageResolved';
export const PAGE_VIEW = 'at.pageView';
export const COMPLETED_PLEDGE = 'Completed Pledge';
export const LINKED_BANK_ACCOUNT = 'Bank Account Linked';
export const VIEW_FARM = 'Product Viewed';
export const FARM_CLICKED = 'Product Clicked';
export const START_INVESTMENT = 'Checkout Started';
export const INVESTMENT_AMOUNT = 'Investment Step 1 Completed';
export const INVESTMENT_PROFILE = 'at.farmInvestmentUserInfoInputted';
export const INVESTMENT_OWNERSHIP = 'Investment Step 2 Completed';
export const INVESTMENT_SIGNATURES = 'Investment Step 3 Completed';
export const INVESTMENT_FUNDING = 'Investment Step 4 Completed';
export const INVESTMENT_ACCREDITATION = 'Investment Step 5 Completed';
export const INVESTMENT_STEP = 'Checkout Step Completed';
export const CHECKOUT_STEP_VIEWED = 'Checkout Step Viewed';
export const COMPLETED_INVESTMENT = 'Investment Step 6 Completed';
export const INVESTMENT_CONFIRMATION = 'Order Completed';
export const ORDER_CANCELLED = 'Order Cancelled';
export const PAYMENT_INFO_ENTERED = 'Payment Info Entered';
export const WALLET_FUNDS_ADDED = 'Wallet Funds Added';
export const EXPLAINER_WATCH_START = 'Video Started';
export const EXPLAINER_WATCH_END = 'Video Ended';
export const DOCUMENT_VIEWED = 'Document Viewed';
export const BEGIN_INVESTING = 'Begin Investing';
export const UPDATED_PROFILE = 'Updated Profile';
export const NEWSLETTER_SUBSCRIPTION = 'Newsletter Subscription';
export const FORM_SUBMITTED = 'Form Submitted';
export const GET_STARTED = 'Get Started';
export const LINK_CLICKED = 'Link Clicked';
export const FILE_DOWNLOADED = 'File Downloaded';
export const PROMOTION_CLICKED = 'Promotion Clicked';
export const PROMOTION_VIEWED = 'Promotion Viewed';
export const ADVISOR_REQUEST_INTRODUCTION = 'Advisor Request Introduction';
export const VIEWED_REALIZED_TRACK_RECORD = 'Viewed Realized Track Record';
